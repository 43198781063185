<template>
  <div class="container-fluid">
    <b-modal
      ref="order-detail-modal"
      size="xl"
      :title="`${$t('orderMsgs.order')} # ${orderNum || orderId}`"
      scrollable
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
      content-class="order-details-modal-content"
    >
      <!-- Order details modal header -->
      <template #modal-header="{ close }">
        <h5 class="modal-title">{{ $t('orderMsgs.order') }} # {{ orderNum || orderId }}</h5>

        <span aria-label="Close">
          <b-dropdown
            size="sm"
            variant="link"
            no-caret
            right
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
            "
          >
            <template #button-content>
              <button
                type="button"
                class="close"
                style="margin-top: -1.3rem !important; margin-right: -1.2rem !important"
              >
                <i class="fas fa-bolt fa-sm" />
              </button>
            </template>

            <!-- <b-dropdown-item href="#" @click.prevent="openPostMaterialModal()">
              <i class="fas fa-cubes order-actions-icon" />
              {{ $t('materialMsgs.addMaterial') }}
            </b-dropdown-item> -->

            <b-dropdown-item href="#" @click.prevent="openPostInvoiceModal()">
              <i class="fas fa-file-invoice-dollar order-actions-icon" />
              {{ $t('invoiceMsgs.manageInvoice') }}
            </b-dropdown-item>

            <b-dropdown-item href="#" @click.prevent="openPostDeliveryNoteModal()">
              <i class="fas fa-receipt order-actions-icon" />
              {{ $t('deliveryNoteMsgs.manageDeliveryNote') }}
            </b-dropdown-item>

            <b-dropdown-item href="#" @click.prevent="openEditLaborHrsModal()">
              <i class="fas fa-hammer order-actions-icon" />
              {{ $t('orderMsgs.manageLaborHrs') }}
            </b-dropdown-item>

            <b-dropdown-item href="#" @click.prevent="openAttachDrawFilesModal()">
              <i class="fas fa-file-upload order-actions-icon" />
              {{ $t('orderMsgs.attachDrawingFiles') }}
            </b-dropdown-item>

            <b-dropdown-divider v-if="isSheetPrintDataLoaded || (isDelNotePrintDataLoaded && hasDelNote)" />

            <b-dropdown-item href="#" @click.prevent="printOrderSheet" v-if="isSheetPrintDataLoaded">
              <i class="fas fa-print order-actions-icon" />
              {{ $t('orderMsgs.printOrderSheet') }}
            </b-dropdown-item>

            <b-dropdown-item href="#" @click.prevent="printDelNote" v-if="isDelNotePrintDataLoaded && hasDelNote">
              <i class="fas fa-print order-actions-icon" />
              {{ $t('deliveryNoteMsgs.printDeliveryNote') }}
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item href="#" @click.prevent="gotoEditOrder()">
              <i class="far fa-edit order-actions-icon" />
              {{ $t('orderMsgs.editOrder') }}
            </b-dropdown-item>
            <b-dropdown-item href="#" @click.prevent="confirmDeleteOrder()">
              <i class="far fa-trash-alt order-actions-icon text-danger" />
              <span class="text-danger order-actions-icon">
                {{ $t('orderMsgs.deleteOrder') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>

          <button type="button" class="close" @click="close()">×</button>
        </span>
      </template>

      <!-- Top nav tabs -->
      <b-tabs content-class="mt-3" v-model="tabIndex">
        <b-tab :title="$t('overview')" lazy>
          <order-overview-tab
            :showTab="true"
            :orderId="orderId"
            ref="overviewTab"
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER, USER_ROLES.CALCULATOR].includes(
                getLoggedInUser.role
              )
            "
          />
          <order-calc-overview-tab :showTab="true" :orderId="orderId" ref="overviewTab" v-else />
        </b-tab>

        <!-- <b-tab
          :title="$t('materialMsgs.materials')"
          lazy
          v-if="[USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)"
        >
          <order-materials-tab :orderId="orderId" ref="usedMaterialsTab" />
        </b-tab> -->

        <b-tab :title="$t('billPaperMsgs.bills')" lazy>
          <order-bills-tab :orderId="orderId" ref="orderBillsTab" />
        </b-tab>

        <b-tab :title="$t('orderMsgs.drawingFiles')" lazy>
          <order-drawing-files-tab :orderId="orderId" ref="drawingFilesTab" />
        </b-tab>

        <b-tab
          :title="`${$t('discussionMsgs.comments')} ${commentsCount > 0 ? ` (${commentsCount})` : ''}`"
          lazy
          v-if="[USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)"
        >
          <discussion-tab
            :orderId="orderId"
            ref="discussionTab"
            @commentsUpdated="fetchCommentCount"
            :commentId="commentId"
          />
        </b-tab>
        <b-tab
          :title="$t('activityMsgs.activities')"
          lazy
          v-if="[USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)"
        >
          <activities-tab :orderId="orderId" ref="activitiesTab" />
        </b-tab>
      </b-tabs>
    </b-modal>

    <order-sheet-print
      v-if="
        orderId &&
        [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
      "
      ref="orderSheetPrint"
      :orderId="orderId"
      @dataLoaded="isSheetPrintDataLoaded = true"
    />

    <multi-page-del-note-print
      v-if="
        orderId &&
        [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
      "
      ref="delNotePrint"
      :orderId="orderId"
      @dataLoaded="isDelNotePrintDataLoaded = true"
      @noteFound="hasDelNote = true"
    />

    <post-delivery-note-modal
      :showModal="modals.showPostDeliveryNoteModal"
      :orderId="orderId"
      @close="hidePostDeliveryNoteModal"
    />

    <post-invoice-modal :showModal="modals.showPostInvoiceModal" :orderId="orderId" @close="hidePostInvoiceModal" />

    <post-used-material-modal
      :showModal="modals.showPostUsedMaterialModal"
      :orderId="orderId"
      :usedMaterialId="modals.selectedUsedMaterialId"
      @close="hidePostMaterialModal"
      @closeUpdate="hideUpdatePostMaterialModal"
    />

    <edit-order-labor-hrs-modal
      :showModal="modals.showEditLaborHrsModal"
      :orderId="orderId"
      @close="hideEditLaborHrsModal"
      @closeUpdate="hideUpdateEditLaborHrsModal"
    />

    <attach-drawing-files-modal
      :showModal="modals.showAttachDrawFilesModal"
      :orderId="orderId"
      @close="hideAttachDrawFilesModal"
      @closeUpdate="hideUpdateAttachDrawFilesModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLES } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import OrderOverviewTab from './components/OrderOverviewTab';
import OrderCalcOverviewTab from './components/OrderCalcOverviewTab';
// import OrderMaterialsTab from './components/OrderMaterialsTab';
import OrderBillsTab from './components/OrderBillsTab';
import OrderDrawingFilesTab from './components/OrderDrawingFilesTab';
import DiscussionTab from './components/DiscussionTab';
import ActivitiesTab from './components/ActivitiesTab';
import PostInvoiceModal from '../Invoices/PostInvoiceModal';
import PostDeliveryNoteModal from '../DeliveryNotes/PostDeliveryNoteModal';
import PostUsedMaterialModal from '../UsedMaterials/PostUsedMaterialModal';
import OrderSheetPrint from './OrderSheetPrint';
import MultiPageDelNotePrint from '../DeliveryNotes/MultiPageDelNotePrint.vue';
import EditOrderLaborHrsModal from '../EditOrderLaborHrsModal';
import AttachDrawingFilesModal from '../DrawingFiles/AttachDrawingFilesModal';

export default {
  name: 'OrderDetailModal',

  props: {
    showModal: Boolean,
    orderId: Number,
    orderNum: String,
    selectedTabIndex: Number,
    commentId: Number,
  },

  components: {
    OrderOverviewTab,
    OrderCalcOverviewTab,
    // OrderMaterialsTab,
    OrderBillsTab,
    OrderDrawingFilesTab,
    DiscussionTab,
    ActivitiesTab,
    PostInvoiceModal,
    PostDeliveryNoteModal,
    PostUsedMaterialModal,
    OrderSheetPrint,
    MultiPageDelNotePrint,
    EditOrderLaborHrsModal,
    AttachDrawingFilesModal,
  },

  data() {
    return {
      order: undefined,

      isLoading: false,
      isSheetPrintDataLoaded: false,
      isDelNotePrintDataLoaded: false,
      hasDelNote: false,
      commentsCount: 0,

      tabIndex: 0,

      modals: {
        showPostDeliveryNoteModal: false,
        showPostInvoiceModal: false,
        showPostUsedMaterialModal: false,
        showEditLaborHrsModal: false,
        showAttachDrawFilesModal: false,
      },
      USER_ROLES,
    };
  },

  methods: {
    ...mapActions(['getOrder', 'deleteOrder', 'getOrdersCosts', 'getCommentCount']),
    formatDateTime,

    hideModal() {
      this.order = undefined;
      this.modals.showPostDeliveryNoteModal =
        this.modals.showPostInvoiceModal =
        this.modals.showPostUsedMaterialModal =
        this.modals.showEditLaborHrsModal =
          false;
      this.isLoading = this.isSheetPrintDataLoaded = this.isDelNotePrintDataLoaded = this.hasDelNote = false;
      this.commentsCount = this.tabIndex = 0;
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs['order-detail-modal'].hide();
      this.hideModal();
    },

    async confirmDeleteOrder() {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(this.$t('orderMsgs.deleteConfirm'), {
          title: this.$t('generalMsgs.suretyMsg'),
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('generalMsgs.yes'),
          cancelTitle: this.$t('generalMsgs.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          await this.deleteOrder(this.orderId);
          this.hideModalManual();
          this.makeToast('success', this.$t('generalMsgs.deletedSuccess'));
        }
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
    },

    gotoEditOrder() {
      this.$router.push({ name: 'edit-order', params: { id: this.orderId } });
      this.hideModalManual();
    },

    openPostDeliveryNoteModal() {
      this.modals.showPostDeliveryNoteModal = true;
    },

    hidePostDeliveryNoteModal() {
      this.modals.showPostDeliveryNoteModal = false;
      if (this.$refs['overviewTab']) {
        this.$refs['overviewTab'].fetchDeliveryNote();
      }
      if (this.$refs.delNotePrint) {
        this.$refs.delNotePrint.fetchReqData();
      }
    },

    openPostInvoiceModal() {
      this.modals.showPostInvoiceModal = true;
    },

    hidePostInvoiceModal() {
      this.modals.showPostInvoiceModal = false;
      if (this.$refs['overviewTab']) {
        this.$refs['overviewTab'].fetchInvoice();
      }
    },

    openEditLaborHrsModal() {
      this.modals.showEditLaborHrsModal = true;
    },

    hideEditLaborHrsModal() {
      this.modals.showEditLaborHrsModal = false;
    },

    hideUpdateEditLaborHrsModal() {
      this.modals.showEditLaborHrsModal = false;
      if (this.$refs['overviewTab']) {
        this.$refs['overviewTab'].fetchOrderCost();
      }
    },

    // openPostMaterialModal() {
    //   this.modals.showPostUsedMaterialModal = true;
    // },

    hidePostMaterialModal() {
      this.modals.showPostUsedMaterialModal = false;
    },

    hideUpdatePostMaterialModal() {
      this.hidePostMaterialModal();
      if (this.$refs['overviewTab']) {
        this.$refs['overviewTab'].fetchOrderCost();
      }
      if (this.$refs['usedMaterialsTab']) {
        this.$refs['usedMaterialsTab'].hideUpdatePostUsedMaterialModal();
      }
    },

    openAttachDrawFilesModal() {
      this.modals.showAttachDrawFilesModal = true;
    },

    hideAttachDrawFilesModal() {
      this.modals.showAttachDrawFilesModal = false;
    },

    hideUpdateAttachDrawFilesModal() {
      this.hideAttachDrawFilesModal();
      if (this.$refs['drawingFilesTab']) {
        this.$refs['drawingFilesTab'].fetchDrawingFiles();
      }
    },

    printOrderSheet() {
      this.$refs.orderSheetPrint.print();
    },

    printDelNote() {
      this.$refs.delNotePrint.print();
    },

    async fetchCommentCount() {
      const response = await this.getCommentCount({ order_ids: [this.orderId] });
      this.commentsCount = response.data.data[0].comment_count;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  computed: {
    ...mapGetters(['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value && this.orderId) {
        if (
          [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(this.getLoggedInUser.role)
        ) {
          this.fetchCommentCount();
        }
        this.$refs['order-detail-modal'].show();
      }
    },
    selectedTabIndex(value) {
      this.tabIndex = value;
    },
  },
};
</script>

<template>
  <b-skeleton-wrapper :loading="isDataLoading">
    <template #loading>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
      <b-skeleton width="90%"></b-skeleton>
      <b-skeleton width="65%"></b-skeleton>
      <b-skeleton width="75%"></b-skeleton>
    </template>

    <div class="row">
      <div class="col-lg-12" v-if="order">
        <div class="card card-block card-stretch card-height">
          <div class="card-body">
            <b-row class="text-center">
              <b-col cols="12" md="12" sm="12">
                <h4>
                  <strong># {{ order.order_number }}</strong>
                </h4>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.receivedDate") }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                <strong>{{ formatDate(order.received_date) }} </strong>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("createdAt") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <strong>{{ formatDateTime(order.created_at) }} </strong>
              </b-col>

              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("createdBy") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <strong
                  >{{
                    order.created_by
                      ? `${order.created_by.first_name} ${order.created_by.last_name}`
                      : "-"
                  }}
                </strong>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("customerMsgs.customer") }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                <i>{{ order.customer.customer_number }}</i> -
                {{ order.customer.name }}
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.constructionProj") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ order.construction_project || "--" }}
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("costCenterMsgs.costCenter") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i>{{ order.cost_center.center_number }}</i>
                {{
                  order.cost_center.name ? ` - ${order.cost_center.name}` : ""
                }}
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.sheetNums") }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                {{ order.sheet_numbers }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.deliveryNote") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <b-skeleton-wrapper :loading="isDelNoteLoading">
                  <template #loading>
                    <b-skeleton width="100%"></b-skeleton>
                  </template>
                  {{ deliveryNote ? deliveryNote.delivery_note_number : "--" }}
                </b-skeleton-wrapper>
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("date") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <b-skeleton-wrapper :loading="isDelNoteLoading">
                  <template #loading>
                    <b-skeleton width="100%"></b-skeleton>
                  </template>
                  {{ deliveryNote ? formatDate(deliveryNote.date) : "--" }}
                </b-skeleton-wrapper>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.invoice") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <b-skeleton-wrapper :loading="isInvoiceLoading">
                  <template #loading>
                    <b-skeleton width="100%"></b-skeleton>
                  </template>
                  {{ invoice ? invoice.invoice_number : "--" }}
                </b-skeleton-wrapper>
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("date") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <b-skeleton-wrapper :loading="isInvoiceLoading">
                  <template #loading>
                    <b-skeleton width="100%"></b-skeleton>
                  </template>
                  {{ invoice ? formatDate(invoice.invoice_date) : "--" }}
                </b-skeleton-wrapper>
              </b-col>
            </b-row>

            <hr />

            <b-row class="mt-2">
              <b-col cols="12" >
                <strong class="mr-2">{{ $t("orderMsgs.enableExternalCost") }}</strong>
                <span>{{ order.external_cost_expected ? $t("generalMsgs.yes") : $t("generalMsgs.no") }}</span>
              </b-col>
            </b-row>

            <!-- <b-row class="mt-2" v-if="order.external_cost_expected">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.estAmount") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.estimated_amount || 0, "currency", "de-DE") }}
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.costType") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                  {{ order.type_of_costs }}
              </b-col>
            </b-row> -->
            
            <b-row class="mt-2" v-if="order.external_cost_expected && order.supplier_invoice">
              <!-- <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.supplier") }}:</strong>
              </b-col> -->
              <!-- <b-col cols="6" md="4" sm="9">
                {{ order.supplier }}
              </b-col> -->
              <b-col cols="12" md="2" sm="3">
                <strong>{{ $t("orderMsgs.supplierinvoice") }}:</strong>
              </b-col>
              <b-col cols="12">
                <template>
                  <file-download-link :file-url="order.supplier_invoice" :show-download-icon="true"/>
                </template>
              </b-col>
            </b-row>

            <hr />

            <h4>{{ $t("orderMsgs.costs") }}</h4>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("materialMsgs.material") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <b-skeleton-wrapper :loading="isCostLoading">
                  <template #loading>
                    <b-skeleton width="100%"></b-skeleton>
                  </template>
                  <span v-if="!isCostLoading">
                    {{ $n(order.cost.material_cost || 0, "currency", "de-DE") }}
                  </span>
                </b-skeleton-wrapper>
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.wage") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <b-skeleton-wrapper :loading="isCostLoading">
                  <template #loading>
                    <b-skeleton width="100%"></b-skeleton>
                  </template>
                  <span v-if="!isCostLoading">
                    {{ $n(order.cost.labor_cost || 0, "currency", "de-DE") }}
                  </span>
                </b-skeleton-wrapper>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.total") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <b-skeleton-wrapper :loading="isCostLoading">
                  <template #loading>
                    <b-skeleton width="100%" height="100%"></b-skeleton>
                  </template>
                  <span v-if="!isCostLoading">
                    {{ $n(order.cost.raw_cost || 0, "currency", "de-DE") }}
                  </span>
                </b-skeleton-wrapper>
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong
                  >{{ $t("orderMsgs.total") }} ({{
                    $t("orderMsgs.inclWaste")
                  }}):</strong
                >
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <b-skeleton-wrapper :loading="isCostLoading">
                  <template #loading>
                    <b-skeleton width="100%" height="100%"></b-skeleton>
                  </template>
                  <span v-if="!isCostLoading">
                    {{ $n(order.cost.total_cost || 0, "currency", "de-DE") }}
                  </span>
                </b-skeleton-wrapper>
              </b-col>
            </b-row>

            <hr />

            <h4>{{ $t("orderMsgs.revenue") }}</h4>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("materialMsgs.material") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.material_revenue, "currency", "de-DE") }}
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.wage") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.labor_revenue, "currency", "de-DE") }}
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.total") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.rawRevTotal, "currency", "de-DE") }}
              </b-col>

              <b-col cols="6" md="2" sm="3">
                <strong
                  >{{ $t("orderMsgs.total") }} ({{
                    $t("orderMsgs.inclFactor")
                  }}):</strong
                >
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ $n(order.totalRevenue, "currency", "de-DE") }}
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import { mapActions } from "vuex";
import { formatDate, formatDateTime } from "../../../../common/utils";
import fileDownloadLink from '../../../../components/files/fileDownloadLink.vue';
import { get, head } from "lodash";

export default {
  name: "order-overview-tab",
  components: { fileDownloadLink },

  props: {
    showTab: Boolean,
    orderId: Number,
  },

  data() {
    return {
      order: undefined,
      deliveryNote: undefined,
      invoice: undefined,

      isDataLoading: true,
      isDelNoteLoading: true,
      isInvoiceLoading: true,
      isCostLoading: true,
    };
  },

  methods: {
    ...mapActions([
      "getOrder",
      "getDeliveryNotes",
      "getInvoices",
      "getOrdersCosts",
    ]),
    formatDate,
    formatDateTime,

    async fetchOrder() {
      this.isDataLoading = true;

      const response = await this.getOrder(this.orderId);
      this.order = response.data;
      this.fetchOrderDetails();

      this.isDataLoading = false;
    },

    async fetchOrderDetails() {
      this.fetchDeliveryNote();
      this.fetchInvoice();
      this.fetchOrderCost();
      this.order["rawRevTotal"] =
        this.order.material_revenue + this.order.labor_revenue;
      this.order["totalRevenue"] =
        (this.order.material_revenue + this.order.labor_revenue) *
        this.order.revenue_factor;
    },

    async fetchDeliveryNote() {
      this.isDelNoteLoading = true;
      this.deliveryNote = null;

      if (this.order) {
        try {
          const note = (await this.getDeliveryNotes({ order: this.order.id }))
            .data;

          if (get(note, "length")) {
            this.deliveryNote = head(note);
          }
        } catch (err) {
          this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        }
      }

      this.isDelNoteLoading = false;
    },

    async fetchInvoice() {
      this.isInvoiceLoading = true;
      this.invoice = null;

      if (this.order) {
        try {
          const invoices = (await this.getInvoices({ order: this.order.id }))
            .data;

          if (get(invoices, "length")) {
            this.invoice = head(invoices);
          }
        } catch (err) {
          this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        }
      }

      this.isInvoiceLoading = false;
    },

    async fetchOrderCost() {
      this.isCostLoading = true;

      const costs = (await this.getOrdersCosts({ orderIds: [this.order.id] }))
        .data;
      this.order["cost"] = head(costs) || {};

      this.isCostLoading = false;
    },
  },

  mounted() {
    if (this.orderId) {
      this.fetchOrder();
    }
  },

  computed: {},
};
</script>
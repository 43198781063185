<template>
  <div class="container-fluid">
    <b-modal
      ref="resend-email-modal"
      size="lg"
      :title="`${$t('orderMsgs.resendEmailCalc')}`"
      scrollable
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
      content-class="resend-email-modal-content"
    >
      <!-- Order details modal header -->
      <template #modal-header="{ close }">
        <h5 class="modal-title">{{ $t('orderMsgs.resendEmailCalc') }}</h5>

        <span aria-label="Close">
          <button type="button" class="close" @click="close()">×</button>
        </span>
      </template>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="calculator">
                    {{ $t('roleTitles.calculator') }}
                  </label>
                  <v-select
                    id="calculator"
                    class="form-control v-select-custom"
                    label="email"
                    v-model="calculator"
                    :reduce="(calculator) => calculator.id"
                    :placeholder="$t('orderMsgs.calculatorPlaceholder')"
                    :options="allCalculators"
                    :loading="isCalculatorsLoading"
                    :required="!calculator"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!calculator" v-bind="attributes" v-on="events" />
                    </template>
                    <template slot="option" slot-scope="option">
                      {{ option.first_name }} {{ option.last_name }}
                    </template>
                    />
                    <template slot="selected-option" slot-scope="option">
                      {{ option.first_name }} {{ option.last_name }}
                    </template>
                  </v-select>
                </div>

                <div class="form-group col-md-12">
                  <label for="message">
                    {{ $t('orderMsgs.message') }}
                  </label>
                  <textarea
                    class="form-control"
                    id="message"
                    :placeholder="$t('orderMsgs.messagePlaceholder')"
                    v-model="message"
                    rows="3"
                  />
                </div>
              </div>

              <div class="text-right">
                <b-button type="submit" variant="primary" :disabled="isLoading" style="min-width: 100px">
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{ $t('generalMsgs.send') }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Top nav tabs -->
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { find, get } from 'lodash';
import vSelect from 'vue-select';

export default {
  name: 'ResendEmailModal',

  props: {
    showModal: Boolean,
    rowData: Object,
  },

  components: {
    vSelect,
  },

  data() {
    return {
      order: undefined,
      allCalculators: [],
      calculator: '',
      isCalculatorsLoading: false,
      isLoading: false,
      message: '',
    };
  },

  methods: {
    ...mapActions(['getAllCalculators', 'resendEmailtoCalculator']),
    resetData() {
      this.allCalculators = [];
      this.calculator = '';
      this.message = '';
      this.isLoading = this.isCalculatorsLoading = false;
    },
    hideModal() {
      this.$emit('close');
      this.resetData();
    },
    hideUpdateModal() {
      this.$refs['resend-email-modal'].hide();
      this.$emit('closeUpdate');
      this.resetData();
    },

    hideModalManual() {
      this.$refs['resend-email-modal'].hide();
      this.hideModal();
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },

    async fetchCalculators(params = {}) {
      this.isCalculatorsLoading = true;
      try {
        const response = await this.getAllCalculators({
          // limit: this.pageSize,
          // offset: (pageNum - 1) * this.pageSize,
          ...params,
        });
        this.allCalculators = response.data;
        this.calculator = response.data.length
          ? get(
              find(response.data, (calc) => calc.is_default_calculator),
              'id',
              null
            )
          : null;
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isCalculatorsLoading = false;
    },
    async onSubmit() {
      try {
        const data = {
          drawing_file: this.rowData.id,
          calculator: this.calculator,
          calculator_message: this.message,
        };
        //api call will be made here
        await this.resendEmailtoCalculator(data);

        this.makeToast('success', this.$t('orderMsgs.drawingFilesentSuccess'));
        this.hideUpdateModal();

        this.isLoading = false;
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
    },
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.$refs['resend-email-modal'].show();
        this.fetchCalculators();
      }
    },
  },
};
</script>

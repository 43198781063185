<template>
  <div class="d-flex m-2">
    <b-avatar
      v-if="getLoggedInUser.first_name && getLoggedInUser.last_name"
      variant="primary"
      size="40px"
      class="ml-2"
      :text="`${getLoggedInUser.first_name[0].toUpperCase()}${getLoggedInUser.last_name[0].toUpperCase()}`"
    />
    <b-avatar variant="primary" size="40px" v-else />
    <div class="w-100 ml-4">
      <form @submit.prevent="onSubmit">
        <vue-tribute :options="popUpOptions">
          <textarea
            id="commentText"
            class="form-control mb-3"
            rows="3"
            :placeholder="$t('discussionMsgs.commentBoxPlaceholder')"
            v-model="comment"
            :disabled="isPosting"
            required
          />
        </vue-tribute>
        <div class="text-right mt-2">
          <b-button type="submit" variant="primary" :disabled="isPosting" style="min-width: 100px">
            <i v-if="isPosting" class="fas fa-circle-notch fa-spin" />
            <span v-else>
              {{ $t('generalMsgs.post') }}
            </span>
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueTribute from 'vue-tribute';
import { find } from 'lodash';
import { getUsername } from '../../../../common/utils';

export default {
  name: 'post-comment',

  props: {
    orderId: Number,
    users: Array
  },

  data() {
    return {
      comment: null,
      isPosting: false,

      mentions: []
    };
  },

  components: { VueTribute },

  methods: {
    ...mapActions(['createComment', 'getAllUsers']),

    async onSubmit() {
      this.isPosting = true;

      try {
        var commentText = this.comment;
        this.mentions = [];
        const matches = [...commentText.matchAll(/\B@\w+/g)];
        for (const match of matches) {
          const user = find(this.users, user => match[0] === getUsername(user, true));
          if (user) {
            this.mentions.push({
              user: user.id,
              mentionPos: match.index
            });
            commentText = commentText.replace(match[0], '@{}');
          }
        }
        const data = {
          text: commentText,
          commenter: this.getLoggedInUser.id,
          object_id: this.orderId,
          object_type: 'order',
          mentions: this.mentions
        };
        await this.createComment(data);
        this.comment = null;
        this.makeToast('success', this.$t('discussionMsgs.commentCreated'));
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
      this.isPosting = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500
      });
    }
  },

  computed: {
    ...mapGetters(['getLoggedInUser']),

    popUpOptions() {
      return {
        collection: [
          {
            trigger: '@',
            containerClass: 'tribute-container dropdown-menu',
            itemClass: 'dropdown-item svg-icon',
            selectClass: 'active-custom',
            values: this.users
              .filter(user => user.id !== this.getLoggedInUser.id)
              .map(user => ({
                key: getUsername(user),
                value: `${user.first_name} ${user.last_name}`
              })),
            selectTemplate: item => {
              return item ? `@${item.original.key}` : '';
            },
            menuItemTemplate(item) {
              const listView = `
                <div class="d-flex align-items-center">
                  <div class="avatar-wrapper d-flex justify-content-center align-items-center">
                    <span class="avatar">${item.original.value
                      .split(' ')[0][0]
                      .toUpperCase()}${item.original.value.split(' ')[1][0].toUpperCase()}</span>
                  </div>
                  <div class="ml-3">
                    ${item.original.value}
                  </div>
                </div>
              `;
              return listView;
            }
          }
        ],
        noMatchTemplate: () => {
          return `<li>${this.$t('discussionMsgs.noMentionMatch')}</li>`;
        }
      };
    }
  }
};
</script>

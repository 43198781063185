<template>
  <div class="mb-3">
    <div class="w-100">
      <div class="d-flex">
        <b-avatar
          v-if="activity.actor.first_name && activity.actor.last_name"
          variant="primary"
          size="40px"
          :text="`${activity.actor.first_name[0].toUpperCase()}${activity.actor.last_name[0].toUpperCase()}`"
        />
        <b-avatar variant="primary" size="50px" v-else />
        <div class="ml-3 d-flex flex-column w-100">
          <p class="mb-1">
            <strong>{{ activity.actor.first_name }} {{ activity.actor.last_name }} </strong>
            {{ $t(`activityMsgs.activityTypes.${activity.action}`) }}
            <strong v-if="activity.action.indexOf('comment') === -1">{{
              `${ACTIVITY_EXTRA_MAPPINGS(activity.action, activity.extra)}`
            }}</strong>
            <br />
            <small class="ml-auto text-muted">
              {{ formatDateTime(activity.created_at) }}
            </small>
          </p>
          <div class="card card-block card-stretch card-height mb-1" v-if="activity.action === 'posted_comment'">
            <div class="card-body p-2">
              {{ ACTIVITY_EXTRA_MAPPINGS(activity.action, activity.extra) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime, ACTIVITY_EXTRA_MAPPINGS } from '../../../../common/utils';

export default {
  name: 'activity-list-item',

  props: {
    activity: Object
  },

  methods: {
    ACTIVITY_EXTRA_MAPPINGS,
    formatDateTime
  }
};
</script>

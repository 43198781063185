<template>
  <div>
    <!-- Add Comment Card -->
    <post-comment :orderId="orderId" class="mt-4" :users="users" />
    <h5>
      {{ $t('discussionMsgs.comments') }}
      {{ allComments.length > 0 ? `(${$n(allComments.length, 'number', 'de-DE')})` : '' }}
    </h5>
    <b-skeleton-wrapper :loading="isInitialLoading">
      <template #loading>
        <div class="m-2">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
          <b-skeleton width="90%"></b-skeleton>
          <b-skeleton width="65%"></b-skeleton>
          <b-skeleton width="75%"></b-skeleton>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="80%"></b-skeleton>
        </div>
      </template>

      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="m-2">
            <!-- Comments list -->
            <comment-list-item
              v-for="(comment, index) in allComments"
              :key="index"
              :id="`comment-${comment.id}`"
              :comment="comment"
              :users="users"
            />

            <div class="card card-block card-stretch card-height" v-if="!allComments.length">
              <div class="card-body text-center">
                <i class="fas fa-info-circle mr-1"></i> {{ $t('discussionMsgs.noComments') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { envConfig } from '../../../../config/envConfig';
import CommentListItem from './CommentListItem.vue';
import PostComment from './PostComment.vue';

export default {
  name: 'discussion-tab',

  props: {
    orderId: Number,
    commentId: Number
  },

  components: {
    CommentListItem,
    PostComment
  },

  data() {
    return {
      allComments: [],
      isInitialLoading: false,
      connection: null,

      users: []
    };
  },

  methods: {
    ...mapActions(['getComments', 'getAllUsers']),

    async fetchUsers() {
      try {
        const response = await this.getAllUsers({});
        this.users = response.data;
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
    },

    async fetchComments(params = {}) {
      this.isInitialLoading = true;

      try {
        const response = await this.getComments({
          object_id: this.orderId,
          object_type: 'order',
          ...params
        });
        this.allComments = response.data;
        if (this.commentId) {
          this.$nextTick(() => {
            const comment = document.querySelector(`#comment-${this.commentId}`);
            if (comment) comment.scrollIntoView({ behavior: 'smooth' });
          });
        }
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isInitialLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500
      });
    }
  },

  mounted() {
    this.fetchUsers();
    this.fetchComments();
  },

  created() {
    var ws_scheme = envConfig.djangoServerProtocol == 'https' ? 'wss' : 'ws';
    this.connection = new WebSocket(
      `${ws_scheme}://${envConfig.djangoServerAddr}/ws/comments/${this.orderId}/?token=${this.getAuthToken}`
    );
    this.connection.onmessage = event => {
      const data = JSON.parse(event.data).message;
      if (data.action === 'Create') {
        this.allComments.unshift(data.comment);
      } else if (data.action === 'Update') {
        let commentToUpate = this.allComments.find(comment => comment.id === data.comment.id);
        commentToUpate.is_edited = data.comment.is_edited;
        commentToUpate.mentions = data.comment.mentions;
        commentToUpate.text = data.comment.text;
      } else {
        this.allComments = this.allComments.filter(comment => comment.id !== data.comment.id);
      }
      this.$emit('commentsUpdated');
    };
    this.connection.onerror = () => {
      this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
    };
  },

  destroyed() {
    this.connection.close();
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getLoggedInUser'])
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"m-2"},[_c('b-skeleton-table',{attrs:{"rows":5,"columns":3,"table-props":{
            'table-class': 'table-loader',
            responsive: true,
          }}})],1)]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"m-2"},[(!_vm.totalDrawingFiles)?_c('div',{staticClass:"card card-block card-stretch card-height"},[_c('div',{staticClass:"card-body text-center"},[_c('i',{staticClass:"fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4",staticStyle:{"font-size":"3rem"}}),_c('h4',[_vm._v(_vm._s(_vm.$t('orderMsgs.noOrderDrawingFiles')))]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('orderMsgs.clickAddDrawingFileBelow'))+" ")]),(
                  [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(
                    _vm.getLoggedInUser.role
                  )
                )?_c('a',{staticClass:"btn btn-primary mt-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openAttachDrawFilesModal()}}},[_vm._v(" "+_vm._s(_vm.$t('orderMsgs.uploadDrawingFiles'))+" ")]):_vm._e()])]):_c('div',{staticClass:"table-responsive"},[_c('b-table',{attrs:{"fields":_vm.tableColumns,"items":_vm.allDrawingFiles,"busy":_vm.isLoading,"head-variant":"primary","hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(file)",fn:function(data){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadDrawingFileFile(data.item.file_url)}}},[_vm._v(_vm._s(data.item.file_name))])]}},{key:"cell(sentToCalculatorTime)",fn:function(data){return [(data.item.emailed_timestamp)?_c('span',[_vm._v(_vm._s(_vm.formatDateTime(data.item.emailed_timestamp)))]):_c('span',[_vm._v("--")])]}},{key:"cell(calculator)",fn:function(data){return [(data.item.calculator_name)?_c('span',[_vm._v(_vm._s(data.item.calculator_name))]):_c('span',[_vm._v("--")])]}},{key:"cell(uploadedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDateTime(data.item.created_at))+" ")]}},{key:"cell(uploadedBy)",fn:function(data){return [(data.item.created_by)?_c('span',[_vm._v(" "+_vm._s(data.item.created_by.first_name)+" "+_vm._s(data.item.created_by.last_name)+" ")]):_vm._e()]}},{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},(
                  [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(
                    _vm.getLoggedInUser.role
                  )
                )?{key:"cell(actions)",fn:function(data){return [_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",modifiers:{"hover":true,"top":true}}],attrs:{"href":"#","title":_vm.$t('orderMsgs.resendEmail')},on:{"click":function($event){$event.preventDefault();return _vm.resendEmail(data.item)}}},[_c('i',{staticClass:"far fa-paper-plane"})]),_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"href":"#","title":_vm.$t('remove')},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteDrawingFile(data.item.id)}}},[_c('i',{staticClass:"far fa-trash-alt text-danger"})])]}}:null],null,true)}),(_vm.totalDrawingFiles > _vm.pageSize)?_c('b-pagination',{attrs:{"value":_vm.currentPage,"total-rows":_vm.totalDrawingFiles,"per-page":_vm.pageSize,"align":"center"},on:{"change":_vm.onPageChange}}):_vm._e()],1)])])])]),_c('attach-drawing-files-modal',{attrs:{"showModal":_vm.modals.showAttachDrawFilesModal,"orderId":_vm.orderId},on:{"close":_vm.hideAttachDrawFilesModal,"closeUpdate":_vm.hideUpdateAttachDrawFilesModal}}),_c('resend-email-modal',{attrs:{"showModal":_vm.modals.showResendFileModal,"rowData":_vm.modals.resendFileData},on:{"close":_vm.hideResendEmailModal,"closeUpdate":_vm.hideUpdateResendEmailModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-skeleton-wrapper :loading="isInitialLoading">
      <template #loading>
        <div class="m-2">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
          <b-skeleton width="90%"></b-skeleton>
          <b-skeleton width="65%"></b-skeleton>
          <b-skeleton width="75%"></b-skeleton>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="80%"></b-skeleton>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="m-2">
          <!-- Activities list -->
          <activity-list-item v-for="(activity, index) in allActivities" :key="index" :activity="activity" />

          <div class="text-center">
            <b-button
              class="text-center"
              variant="outline-secondary"
              @click="loadMore"
              size="sm"
              :disabled="isLoadingMore"
              style="min-width: 100px"
              v-if="allActivities.length < totalActivities"
            >
              <i v-if="isLoadingMore" class="fas fa-circle-notch fa-spin" />
              <span v-else>{{ $t('activityMsgs.loadMore') }}</span>
            </b-button>

            <div
              class="card card-block card-stretch card-height"
              v-if="!isInitialLoading && !totalActivities"
            >
              <div class="card-body text-center">
                <i
                  class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4"
                  style="font-size: 3rem"
                />
                <h4>{{ $t("activityMsgs.noActivities") }}</h4>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ActivityListItem from './ActivityListItem.vue';

export default {
  name: 'activities-tab',

  props: {
    orderId: Number
  },

  components: {
    ActivityListItem
  },

  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      allActivities: [],
      isInitialLoading: false,
      isLoadingMore: false,
      totalActivities: null
    };
  },

  methods: {
    ...mapActions(['getActivities']),

    async fetchActivities(params = {}, isInitial = false) {
      this.isInitialLoading = isInitial;

      try {
        const response = await this.getActivities({
          ...(this.pageSize && {
            limit: this.pageSize,
            offset: (this.pageNum - 1) * this.pageSize
          }),
          object_id: this.orderId,
          object_type: 'order',
          ...params
        });
        if (response.data.results) {
          this.allActivities.push(...response.data.results);
          this.totalActivities = response.data.count;
        }
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isInitialLoading = false;
    },

    async loadMore() {
      this.isLoadingMore = true;
      this.pageNum = this.pageNum + 1;
      await this.fetchActivities();
      this.isLoadingMore = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500
      });
    }
  },

  mounted() {
    this.fetchActivities({}, true);
  },
};
</script>

<template>
  <div class="container-fluid">
    <b-modal
      ref="attach-drawing-files-modal"
      size="lg"
      :title="$t('orderMsgs.attachDrawingFiles')"
      hide-footer
      centered
      static
      lazy
      scrollable
      :no-close-on-backdrop="!!drawingFiles.length"
      :no-close-on-esc="!!drawingFiles.length"
      @close="closeModal"
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12">
                  <div class="custom-control custom-switch custom-switch-color custom-control-inline">
                    <input
                      type="checkbox"
                      class="custom-control-input bg-success"
                      id="customSwitch03"
                      v-model="enableEmailDrawingFiles"
                    />
                    <label class="custom-control-label" for="customSwitch03">{{
                      $t('orderMsgs.enableEmailDrawingFilesMsg')
                    }}</label>
                  </div>
                </div>

                <div class="form-group col-md-12" v-if="enableEmailDrawingFiles">
                  <label for="calculator">
                    {{ $t('roleTitles.calculator') }}
                  </label>
                  <v-select
                    id="calculator"
                    class="form-control v-select-custom"
                    label="email"
                    v-model="calculator"
                    :reduce="(calculator) => calculator.id"
                    :placeholder="$t('orderMsgs.calculatorPlaceholder')"
                    :options="allCalculators"
                    :loading="isCalculatorsLoading"
                    :required="enableEmailDrawingFiles && !calculator"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!calculator" v-bind="attributes" v-on="events" />
                    </template>
                    <template slot="option" slot-scope="option">
                      {{ option.first_name }} {{ option.last_name }}
                    </template>
                    />
                    <template slot="selected-option" slot-scope="option">
                      {{ option.first_name }} {{ option.last_name }}
                    </template>
                  </v-select>
                </div>

                <div class="form-group col-md-12" v-if="enableEmailDrawingFiles">
                  <label for="message">
                    {{ $t('orderMsgs.message') }}
                  </label>
                  <textarea
                    class="form-control"
                    id="message"
                    :placeholder="$t('orderMsgs.messagePlaceholder')"
                    v-model="message"
                    rows="3"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label for="drawingFiles">{{ $t('orderMsgs.drawingFiles') }}</label>
                  <b-form-file
                    :placeholder="$t('orderMsgs.attachDrawingFilesToOrder')"
                    :drop-placeholder="$t('generalMsgs.dropFilesPlaceholder')"
                    :browse-text="$t('generalMsgs.browse')"
                    class="form-control"
                    v-model="drawingFilesBuff"
                    :disabled="isLoading"
                    accept="image/*, application/pdf"
                    multiple
                  >
                  </b-form-file>

                  <!-- Attached files -->
                  <div class="mt-2">
                    <div class="row" v-for="(fileData, index) in drawingFiles" :key="index">
                      <div :class="fileData.isUploading ? 'col-md-8' : 'col-md-10'">
                        <i class="fas fa-file text-primary" />
                        {{ fileData.file.name }}
                      </div>
                      <div class="col-md-2 text-right" v-if="fileData.isUploading">
                        <b-progress :value="fileData.uploadPercent" :max="100" animated class="mt-1"></b-progress>
                      </div>
                      <div class="text-right col-md-2">
                        <a href="#" class="text-danger" @click.prevent="removeAttachedDrawFile(index)">
                          <i class="fas fa-times-circle" /> {{ $t('remove') }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-right">
                <b-button type="submit" variant="primary" :disabled="isLoading" style="min-width: 100px">
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{ $t('billPaperMsgs.upload') }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import vSelect from 'vue-select';
import { find, get } from 'lodash';

export default {
  name: 'AttachDrawingFilesModal',

  props: {
    showModal: Boolean,
    orderId: Number,
  },

  data() {
    return {
      drawingFiles: [],
      drawingFilesBuff: [],

      calculator: null,
      allCalculators: [],
      message: '',

      isLoading: false,
      isCalculatorsLoading: false,
      enableEmailDrawingFiles: false,
    };
  },

  methods: {
    ...mapActions([
      'uploadToPresignedUrl',
      'getDrawingFilesPresignedUrl',
      'addDrawingFilesToOrder',
      'getAllCalculators',
    ]),

    resetData() {
      this.drawingFilesBuff = this.drawingFiles = [];
      this.isLoading = this.isCalculatorsLoading = this.enableEmailDrawingFiles = false;
      this.message = '';
      this.calculator = null;
    },

    async fetchCalculators(params = {}) {
      this.isCalculatorsLoading = true;
      try {
        const response = await this.getAllCalculators({
          // limit: this.pageSize,
          // offset: (pageNum - 1) * this.pageSize,
          ...params,
        });
        this.allCalculators = response.data;
        this.calculator = response.data.length
          ? get(
              find(response.data, (calc) => calc.is_default_calculator),
              'id',
              null
            )
          : null;
      } catch (err) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isCalculatorsLoading = false;
    },

    async closeModal(event) {
      event.preventDefault();

      if (this.drawingFiles.length) {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(this.$t('generalMsgs.cancelOngoingFilesUploadPopup'), {
          title: this.$t('generalMsgs.cancelUpload'),
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('generalMsgs.yes'),
          cancelTitle: this.$t('generalMsgs.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          // Canceling any ongoing upload on modal close.
          this.drawingFiles.forEach((fileData) => {
            if (fileData.uploadCancelTokenSource) {
              fileData.uploadCancelTokenSource.cancel();
            }
          });
          this.hideModalManual();
        }
      } else {
        this.hideModalManual();
      }
    },

    closeAndReset() {
      this.$emit('close');
      this.resetData();
    },

    hideModal(event) {
      if (event.trigger !== 'headerclose') {
        this.$emit('close');
        this.resetData();
      }
    },

    hideModalManual() {
      this.$nextTick(() => {
        this.$refs['attach-drawing-files-modal'].hide();
      });
      this.closeAndReset();
    },

    hideWithUpdateModal() {
      if (this.uploadCancelTokenSource) {
        this.uploadCancelTokenSource.cancel();
      }

      this.$refs['attach-drawing-files-modal'].hide();
      this.$emit('closeUpdate');
      this.resetData();
    },

    removeAttachedDrawFile(fileIndex) {
      if (this.drawingFiles[fileIndex].uploadCancelTokenSource) {
        this.drawingFiles[fileIndex].uploadCancelTokenSource.cancel();
      }
      this.drawingFiles.splice(fileIndex, 1);
    },

    async uploadDrawFile(fileData, url) {
      fileData['url'] = url.split('?')[0];
      fileData.isUploading = true;

      try {
        await this.uploadToPresignedUrl({
          url: url,
          file: fileData.file,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(fileData),
            cancelToken: fileData.uploadCancelTokenSource.token,
          },
        });
      } catch (error) {
        // Handle upload error here (if needed).
      }

      fileData.uploadCancelTokenSource = null;
      fileData.isUploading = false;
      fileData.uploadPercent = 100;
    },

    async uploadAttachedDrawFiles(files) {
      try {
        const urlResp = await this.getDrawingFilesPresignedUrl(
          files.map((fileData) => ({
            file_name: fileData.file.name,
            content_type: fileData.file.type,
          }))
        );

        await Promise.all(files.map((fileData, index) => this.uploadDrawFile(fileData, urlResp.upload_urls[index])));
      } catch (error) {
        // Handle upload error here (if needed).
      }
    },

    async onSubmit() {
      this.isLoading = true;

      if (this.drawingFiles.length) {
        try {
          await this.uploadAttachedDrawFiles(this.drawingFiles);
          const uploadedFileUrls = this.drawingFiles
            .filter((fileData) => !fileData.isUploading && fileData.uploadPercent === 100)
            .map((fileData) => fileData.url);

          if (uploadedFileUrls.length) {
            await this.addDrawingFilesToOrder({
              drawing_files: uploadedFileUrls,
              order: this.orderId,
              ...(this.calculator &&
                this.enableEmailDrawingFiles && {
                  calculator: this.calculator,
                }),
              ...(this.message &&
                this.enableEmailDrawingFiles && {
                  calculator_message: this.message,
                }),
            });

            this.makeToast('success', this.$t('orderMsgs.drawingFileAttachSuccess'));
            this.isLoading = false;
            this.hideWithUpdateModal();
          }
        } catch (error) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
      } else {
        this.makeToast('danger', this.$t('generalMsgs.noFileSelected'));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  components: { vSelect },

  computed: {},

  watch: {
    showModal(value) {
      this.fetchCalculators();
      if (value) {
        this.$refs['attach-drawing-files-modal'].show();
      }
    },
    async drawingFilesBuff(filesVal) {
      if (get(filesVal, 'length')) {
        const newFiles = filesVal.map((file) => ({
          file,
          isUploading: false,
          uploadPercent: 0,
          uploadCancelTokenSource: axios.CancelToken.source(),
        }));
        this.drawingFilesBuff = [];
        this.drawingFiles = this.drawingFiles.concat(newFiles);
      }
    },
  },
};
</script>

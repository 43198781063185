<template>
  <div>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="m-2">
          <b-skeleton-table
            :rows="5"
            :columns="3"
            :table-props="{
              'table-class': 'table-loader',
              responsive: true,
            }"
          ></b-skeleton-table>
        </div>
      </template>

      <div class="row">
        <div class="col-lg-12">
          <div class="m-2">
            <!-- No Bills Data Warning Card -->
            <div
              class="card card-block card-stretch card-height"
              v-if="!totalBills"
            >
              <div class="card-body text-center">
                <i
                  class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4"
                  style="font-size: 3rem"
                />
                <h4>{{ $t("orderMsgs.noOrderBills") }}</h4>

                <p class="text-muted">
                  {{ $t("billPaperMsgs.manageBillsClickBelow") }}
                </p>

                <router-link
                  :to="{ name: 'list-bills' }"
                  class="btn btn-primary mt-2"
                >
                  {{ $t("billPaperMsgs.manageBills") }}
                </router-link>
              </div>
            </div>

            <!-- Use card layout if needed. -->
            <!-- <div class="card card-block card-stretch card-height" v-else>
            <div class="card-body"> -->
            <div class="table-responsive" v-else>
              <b-table
                :fields="tableColumns"
                :items="allBills"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
              >
                <template #cell(file)="data">
                  <a
                    href="#"
                    @click.prevent="downloadBillFile(data.item.pdf_url, data.item.id)"
                    >{{ createBillFileName(data.item) }}</a
                  >
                </template>

                <template #cell(status)="data">
                  <b-badge
                    :class="`border border-${
                      billStatusColors[data.item.status]
                    } text-${billStatusColors[data.item.status]}`"
                    variant="none"
                    v-b-popover.hover.top
                    :title="
                      $t(`billPaperMsgs.parserStatusesDesc.${data.item.status}`)
                    "
                    >{{
                      $t(`billPaperMsgs.parserStatuses.${data.item.status}`)
                    }}
                  </b-badge>
                </template>

                <template #cell(uploadedAt)="data">
                  {{ formatDateTime(data.item.created_at) }}
                </template>

                <template #cell(billDate)="data">
                  {{ data.item.date ? formatDate(data.item.date) : "" }}
                </template>

                <template #cell(laborRevenue)="data">
                  {{ $n(data.item.labor_revenue || 0, "currency", "de-DE") }}
                </template>

                <template #cell(materialRevenue)="data">
                  {{ $n(data.item.material_revenue || 0, "currency", "de-DE") }}
                </template>

                <template #cell(uploadedBy)="data">
                  <span v-if="data.item.created_by">
                    {{ data.item.created_by.first_name }}
                    {{ data.item.created_by.last_name }}
                  </span>
                </template>

                <template #cell(lastDownloadedAt)="data">
                  {{ data.item.last_downloaded_at ? formatDateTime(data.item.last_downloaded_at) : $t("never") }}
                </template>

                <!-- TODO: Integrate actions if needed! -->
                <!-- <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data" style="min-width: 50px">
                  <router-link
                    :to="{
                      name: 'edit-bill',
                      params: { id: data.item.id },
                    }"
                    class="svg-icon mr-2"
                  >
                    <i class="far fa-edit text-info" />
                  </router-link>

                  <a
                    href="#"
                    @click.prevent="confirmDeleteBill(data.item.id)"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template> -->
              </b-table>

              <b-pagination
                v-if="totalBills > pageSize"
                :value="currentPage"
                :total-rows="totalBills"
                :per-page="pageSize"
                @change="onPageChange"
                align="center"
              />
            </div>
          </div>
          <!-- </div>
          </div> -->
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  formatDate,
  formatDateTime,
  parseFileNameFromUrl,
} from "../../../../common/utils";
import { DEFAULT_PAGE_SIZE, USER_ROLES } from "../../../../common/constants";
import {get} from 'lodash';

export default {
  name: "order-bills-tab",

  props: {
    orderId: Number,
  },

  components: {},

  data() {
    return {
      allBills: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalBills: 0,
      isLoading: true,

      billStatusColors: {
        success: "success",
        parsing: "secondary",
        error_download: "danger",
        error_parse: "danger",
        error_order_not_found: "danger",
      },
    };
  },

  methods: {
    ...mapActions(["getBillPapers", "downloadFile", "updateBillDownloadedAt"]),
    formatDate,
    formatDateTime,
    parseFileNameFromUrl,

    async fetchBills(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getBillPapers({
        limit: this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        is_archived: false,
        ...params,
        order: this.orderId,
      });
      this.allBills = response.data.results;
      this.currentPage = pageNum;
      this.totalBills = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchBills(pageNum);
    },

    async downloadBillFile(fileUrl, billId) {
      const h = this.$createElement;

      this.makeToast(
        "primary",
        h("span", [
          h("i", { class: "fas fa-download mr-2" }),
          this.$t("generalMsgs.downloadingFile"),
        ]),
        "b-toaster-bottom-left"
      );

      let bill = this.allBills.find(bill => bill.id === billId);
      let fileName = null;
      if (bill.order_number && bill.drawing_number) {
        fileName = `${bill.order_number.replaceAll('/', '_')}_${bill.drawing_number.replaceAll('/', '_')}.pdf`;
      }
      await this.downloadFile({ fileUrl: fileUrl, removeTimestamp: true, fileName: fileName });
      const downloadedAt = get((await this.updateBillDownloadedAt(billId)), 'data.data.downloaded_at');
      bill['last_downloaded_at'] = downloadedAt;

      this.makeToast(
        "success",
        h("span", [
          h("i", { class: "fas fa-download mr-2" }),
          this.$t("generalMsgs.downloadFileSuccess"),
        ]),
        "b-toaster-bottom-left"
      );
    },

    createBillFileName(bill) {
      let fileName = null;
      if (bill.order_number && bill.drawing_number) {
        fileName = `${bill.order_number.replaceAll('/', '_')}_${bill.drawing_number.replaceAll('/', '_')}.pdf`;
      } else {
        fileName = parseFileNameFromUrl(bill.pdf_url, true);
      }
      return fileName;
    },

    makeToast(variant = null, msg, toaster = undefined) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
        ...(toaster && { toaster }),
      });
    },
  },

  mounted() {
    this.fetchBills();
  },

  computed: {
    ...mapGetters(["getLoggedInUser"]),

    tableColumns() {
      if (this.getLoggedInUser.role === USER_ROLES.CALCULATOR) {
        return [
          { key: "file", label: this.$t("billPaperMsgs.file") },
          { key: "status", label: this.$t("status") },
          { key: "order_number", label: this.$t("orderMsgs.orderNum") },
          { key: "uploadedAt", label: this.$t("billPaperMsgs.uploadedAt") },
          { key: "lastDownloadedAt", label: this.$t("billPaperMsgs.lastDownloaded") },
        ];
      } else {
        return [
          { key: "file", label: this.$t("billPaperMsgs.file") },
          { key: "status", label: this.$t("status") },

          // Parsed Data.
          { key: "order_number", label: this.$t("orderMsgs.orderNum") },
          { key: "billDate", label: this.$t("billPaperMsgs.billDate") },
          { key: "drawing_number", label: this.$t("billPaperMsgs.sheet") },
          { key: "laborRevenue", label: this.$t("orderMsgs.laborRev") },
          { key: "materialRevenue", label: this.$t("orderMsgs.materialRev") },
          { key: "lastDownloadedAt", label: this.$t("billPaperMsgs.lastDownloaded") },

          { key: "uploadedAt", label: this.$t("billPaperMsgs.uploadedAt") },
          { key: "uploadedBy", label: this.$t("billPaperMsgs.uploadedBy") },

          // TODO: Integrate actions if needed!
          // {
          //   key: "actions",
          //   tdClass: "text-right",
          //   thStyle: { minWidth: "80px" },
          // },
        ];
      }
    },
  },
};
</script>
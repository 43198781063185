<template>
  <div>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="m-2">
          <b-skeleton-table
            :rows="5"
            :columns="3"
            :table-props="{
              'table-class': 'table-loader',
              responsive: true,
            }"
          ></b-skeleton-table>
        </div>
      </template>

      <div class="row">
        <div class="col-lg-12">
          <div class="m-2">
            <!-- No DrawingFiles Data Warning Card -->
            <div class="card card-block card-stretch card-height" v-if="!totalDrawingFiles">
              <div class="card-body text-center">
                <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
                <h4>{{ $t('orderMsgs.noOrderDrawingFiles') }}</h4>

                <p class="text-muted">
                  {{ $t('orderMsgs.clickAddDrawingFileBelow') }}
                </p>

                <!-- Open attach drawing files popup. -->
                <a
                  href="#"
                  @click.prevent="openAttachDrawFilesModal()"
                  class="btn btn-primary mt-2"
                  v-if="
                    [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
                      getLoggedInUser.role
                    )
                  "
                >
                  {{ $t('orderMsgs.uploadDrawingFiles') }}
                </a>
              </div>
            </div>

            <!-- Use card layout if needed. -->
            <!-- <div class="card card-block card-stretch card-height" v-else>
            <div class="card-body"> -->
            <div class="table-responsive" v-else>
              <b-table
                :fields="tableColumns"
                :items="allDrawingFiles"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
              >
                <template #cell(file)="data">
                  <a href="#" @click.prevent="downloadDrawingFileFile(data.item.file_url)">{{ data.item.file_name }}</a>
                </template>

                <template #cell(sentToCalculatorTime)="data">
                  <span v-if="data.item.emailed_timestamp">{{ formatDateTime(data.item.emailed_timestamp) }}</span>
                  <span v-else>--</span>
                </template>

                <template #cell(calculator)="data">
                  <span v-if="data.item.calculator_name">{{ data.item.calculator_name }}</span>
                  <span v-else>--</span>
                </template>

                <template #cell(uploadedAt)="data">
                  {{ formatDateTime(data.item.created_at) }}
                </template>

                <template #cell(uploadedBy)="data">
                  <span v-if="data.item.created_by">
                    {{ data.item.created_by.first_name }}
                    {{ data.item.created_by.last_name }}
                  </span>
                </template>

                <!-- Row Actions -->
                <template #head(actions)="">
                  <span></span>
                </template>
                <template
                  #cell(actions)="data"
                  style="min-width: 50px"
                  v-if="
                    [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
                      getLoggedInUser.role
                    )
                  "
                >
                  <a
                    href="#"
                    @click.prevent="resendEmail(data.item)"
                    v-b-popover.hover.top
                    :title="$t('orderMsgs.resendEmail')"
                  >
                    <i class="far fa-paper-plane"></i>
                  </a>

                  <a
                    href="#"
                    class="ml-2"
                    @click.prevent="confirmDeleteDrawingFile(data.item.id)"
                    v-b-popover.hover.top
                    :title="$t('remove')"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template>
              </b-table>

              <b-pagination
                v-if="totalDrawingFiles > pageSize"
                :value="currentPage"
                :total-rows="totalDrawingFiles"
                :per-page="pageSize"
                @change="onPageChange"
                align="center"
              />
            </div>
          </div>
          <!-- </div>
          </div> -->
        </div>
      </div>
    </b-skeleton-wrapper>

    <attach-drawing-files-modal
      :showModal="modals.showAttachDrawFilesModal"
      :orderId="orderId"
      @close="hideAttachDrawFilesModal"
      @closeUpdate="hideUpdateAttachDrawFilesModal"
    />
    <resend-email-modal
      :showModal="modals.showResendFileModal"
      :rowData="modals.resendFileData"
      @close="hideResendEmailModal"
      @closeUpdate="hideUpdateResendEmailModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLES } from '../../../../common/constants';
import { formatDate, formatDateTime, parseFileNameFromUrl } from '../../../../common/utils';
import { DEFAULT_PAGE_SIZE } from '../../../../common/constants';
import AttachDrawingFilesModal from '../../DrawingFiles/AttachDrawingFilesModal';
import ResendEmailModal from '../components/ResendEmailModal.vue';

export default {
  name: 'order-drawing-files-tab',

  props: {
    orderId: Number,
  },

  components: { AttachDrawingFilesModal, ResendEmailModal },

  data() {
    return {
      allDrawingFiles: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalDrawingFiles: 0,
      isLoading: true,

      USER_ROLES,
      modals: {
        showAttachDrawFilesModal: false,
        showResendFileModal: false,
        resendFileData: null,
      },
    };
  },

  methods: {
    ...mapActions(['getOrderDrawingFiles', 'downloadFile', 'deleteOrderDrawingFile', 'resendEmailtoCalculator']),
    formatDate,
    formatDateTime,
    parseFileNameFromUrl,

    async resendEmailWithNoCalculator(rowId) {
      try {
        await this.resendEmailtoCalculator({ drawing_file: rowId });
        this.fetchDrawingFiles();
        this.makeToast('success', this.$t('orderMsgs.drawingFileResentSuccess'));
        this.isLoading = false;
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
    },
    resendEmail(rowData) {
      this.modals.resendFileData = rowData;
      if (rowData.calculator_name === '') {
        this.modals.showResendFileModal = true;
      } else this.resendEmailWithNoCalculator(rowData.id);
    },
    closeResendFileModal() {
      this.modals.showResendFileModal = false;
    },
    openAttachDrawFilesModal() {
      this.modals.showAttachDrawFilesModal = true;
    },

    hideAttachDrawFilesModal() {
      this.modals.showAttachDrawFilesModal = false;
    },
    hideResendEmailModal() {
      this.modals.showResendFileModal = false;
    },

    hideUpdateAttachDrawFilesModal() {
      this.hideAttachDrawFilesModal();
      this.fetchDrawingFiles();
    },
    hideUpdateResendEmailModal() {
      this.hideResendEmailModal();
      this.fetchDrawingFiles();
    },

    async fetchDrawingFiles(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getOrderDrawingFiles({
        limit: this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        ...params,
        order: this.orderId,
      });
      this.allDrawingFiles = response.data.results;
      this.currentPage = pageNum;
      this.totalDrawingFiles = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchDrawingFiles(pageNum);
    },

    async downloadDrawingFileFile(fileUrl) {
      const h = this.$createElement;

      this.makeToast(
        'primary',
        h('span', [h('i', { class: 'fas fa-download mr-2' }), this.$t('generalMsgs.downloadingFile')]),
        'b-toaster-bottom-left'
      );

      await this.downloadFile({ fileUrl: fileUrl, removeTimestamp: true });

      this.makeToast(
        'success',
        h('span', [h('i', { class: 'fas fa-download mr-2' }), this.$t('generalMsgs.downloadFileSuccess')]),
        'b-toaster-bottom-left'
      );
    },

    async confirmDeleteDrawingFile(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(this.$t('orderMsgs.deleteDrawingFileConfirm'), {
          title: this.$t('generalMsgs.suretyMsg'),
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('generalMsgs.yes'),
          cancelTitle: this.$t('generalMsgs.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          await this.deleteOrderDrawingFile(id);
          const refreshPage = this.allDrawingFiles.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchDrawingFiles(refreshPage || 1);
          this.makeToast('success', this.$t('generalMsgs.deletedSuccess'));
        }
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
    },

    makeToast(variant = null, msg, toaster = undefined) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
        ...(toaster && { toaster }),
      });
    },
  },

  mounted() {
    this.fetchDrawingFiles();
  },

  computed: {
    ...mapGetters(['getLoggedInUser']),
    tableColumns() {
      return [
        { key: 'file', label: this.$t('billPaperMsgs.file') },
        { key: 'uploadedAt', label: this.$t('billPaperMsgs.uploadedAt') },
        { key: 'uploadedBy', label: this.$t('billPaperMsgs.uploadedBy') },
        {
          key: 'sentToCalculatorTime',
          label: this.$t('orderMsgs.sentToCalculatorTime'),
        },
        {
          key: 'calculator',
          label: this.$t('roleTitles.calculator'),
        },
        ...([USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(this.getLoggedInUser.role)
          ? [
              {
                key: 'actions',
                tdClass: 'text-right',
                thStyle: { minWidth: '80px' },
              },
            ]
          : []),
      ];
    },
  },
};
</script>

<template>
  <b-skeleton-wrapper :loading="isDataLoading">
    <template #loading>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
      <b-skeleton width="90%"></b-skeleton>
      <b-skeleton width="65%"></b-skeleton>
      <b-skeleton width="75%"></b-skeleton>
    </template>

    <div class="row">
      <div class="col-lg-12" v-if="order">
        <div class="card card-block card-stretch card-height">
          <div class="card-body">
            <b-row class="text-center">
              <b-col cols="12" md="12" sm="12">
                <h4>
                  <strong># {{ order.order_number }}</strong>
                </h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('customerMsgs.customer') }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                <i>{{ order.customer.customer_number }}</i> -
                {{ order.customer.name }}
              </b-col>
            </b-row>

            <!-- Use if needed -->
            <!-- <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("orderMsgs.constructionProj") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                {{ order.construction_project || "--" }}
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t("costCenterMsgs.costCenter") }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <i>{{ order.cost_center.center_number }}</i>
                {{
                  order.cost_center.name ? ` - ${order.cost_center.name}` : ""
                }}
              </b-col>
            </b-row> -->

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.sheetNums') }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                {{ order.sheet_numbers }}
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDate, formatDateTime } from '../../../../common/utils';

export default {
  name: 'OrderCalcOverviewTab',

  props: {
    showTab: Boolean,
    orderId: Number,
  },
  data() {
    return {
      order: undefined,

      isDataLoading: true,
    };
  },

  methods: {
    ...mapActions(['getOrder']),
    formatDate,
    formatDateTime,

    async fetchOrder() {
      this.isDataLoading = true;

      const response = await this.getOrder(this.orderId);
      this.order = response.data;

      this.isDataLoading = false;
    },
  },

  mounted() {
    if (this.orderId) {
      this.fetchOrder();
    }
  },

  computed: {},
};
</script>
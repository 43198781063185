<template>
  <div class="card card-block card-stretch card-height mb-3" @mouseover="hover = true" @mouseleave="hover = false">
    <div class="card-body d-flex">
      <b-avatar
        v-if="comment.commenter.first_name && comment.commenter.last_name"
        variant="primary"
        size="40px"
        :text="`${comment.commenter.first_name[0].toUpperCase()}${comment.commenter.last_name[0].toUpperCase()}`"
      />
      <b-avatar variant="primary" size="50px" v-else />
      <div class="w-100 ml-3">
        <div class="d-flex">
          <strong>{{ comment.commenter.first_name }} {{ comment.commenter.last_name }} </strong>
          <small class="ml-auto">
            {{ formatDateTime(comment.created_at) }}
          </small>
        </div>
        <div class="mt-2 px-0">
          <template v-if="isEditing">
            <vue-tribute :options="popUpOptions">
              <textarea class="form-control" rows="2" v-model="editText" :disabled="isPosting" />
            </vue-tribute>
          </template>
          <template v-else>
            <span v-html="commentText" />
          </template>
        </div>

        <div class="mt-2 float-right" v-if="isEditing" :disabled="isPosting">
          <b-button variant="outline-danger" pill @click="cancelEdit" size="sm" class="comment-edit-btn mr-2">
            <i class="fas fa-sm fa-times"
          /></b-button>
          <b-button
            variant="outline-primary"
            pill
            @click="onSubmit"
            size="sm"
            class="comment-edit-btn"
            :disabled="isPosting"
          >
            <i class="fas fa-sm fa-check"
          /></b-button>
        </div>

        <sub class="text-muted text-sm" v-if="comment.is_edited && !isEditing"
          ><i>({{ $t('discussionMsgs.edited') }})</i></sub
        >
        <div class="mt-2 float-right" v-if="comment.commenter.id === getLoggedInUser.id && !isEditing">
          <span v-show="hover">
            <b-button
              variant="outline-primary"
              class="comment-edit-btn mr-2"
              pill
              @click="editComment"
              size="sm"
              v-b-popover.hover.top
              :title="$t('generalMsgs.edit')"
            >
              <i class="fas fa-sm fa-pen" />
            </b-button>
            <b-button
              variant="outline-danger"
              pill
              @click="confirmDeleteComment(comment.id)"
              size="sm"
              v-b-popover.hover.top
              :title="$t('generalMsgs.delete')"
              class="comment-edit-btn"
            >
              <i class="fas fa-sm fa-trash" />
            </b-button>
          </span>
        </div>
      </div>
    </div>

    <div class="mention-details" role="tooltip" :id="`mention-${comment.id}`">
      <div class="d-flex align-items-center justify-content-center m-1">
        <b-avatar :id="`avatar-${this.comment.id}`" variant="primary" size="25px" :text="avatarText" />
        <div class="ml-2" :id="`name-${comment.id}`">{{ mentionName }}</div>
      </div>
      <div class="mention-detail-arrow" data-popper-arrow></div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { mapActions, mapGetters } from 'vuex';
import VueTribute from 'vue-tribute';
import { find } from 'lodash';
import { formatDateTime, getUsername } from '../../../../common/utils';

export default {
  name: 'comment-list-item',

  props: {
    comment: Object,
    users: Array
  },

  components: { VueTribute },

  data() {
    return {
      editText: '',
      isPosting: false,
      isEditing: false,
      hover: false,

      mentionName: null,
      avatarText: null,
      mentions: []
    };
  },

  methods: {
    ...mapActions(['updateComment', 'deleteComment', 'getAllUsers']),
    formatDateTime,

    editComment() {
      this.isEditing = true;

      this.editText = this.comment.text;
      for (const mention of this.comment.mentions) {
        const user = find(this.users, user => user.id === mention.user);
        if (user) {
          const username = getUsername(user, true);
          this.editText = this.editText.replace('@{}', username);
        }
      }
    },

    cancelEdit() {
      this.editText = '';
      this.isEditing = false;
    },

    async confirmDeleteComment(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(this.$t('discussionMsgs.deleteCommentConfirm'), {
          title: this.$t('generalMsgs.suretyMsg'),
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('generalMsgs.yes'),
          cancelTitle: this.$t('generalMsgs.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        });
        if (isConfirmed) {
          await this.deleteComment(id);
          this.makeToast('success', this.$t('generalMsgs.deletedSuccess'));
        }
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
      this.hover = false;
    },

    async onSubmit() {
      this.isPosting = true;

      if (this.editText) {
        try {
          this.mentions = [];
          var commentText = this.editText;
          const matches = [...commentText.matchAll(/\B@\w+/g)];
          for (const match of matches) {
            const user = find(this.users, user => match[0] === getUsername(user, true));
            if (user) {
              this.mentions.push({
                user: user.id,
                mentionPos: match.index
              });
              commentText = commentText.replace(match[0], '@{}');
            }
          }
          const data = {
            text: commentText,
            commenter: this.getLoggedInUser.id,
            mentions: this.mentions
          };
          await this.updateComment({
            id: this.comment.id,
            data
          });
          this.makeToast('success', this.$t('discussionMsgs.commentUpdated'));
        } catch (error) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
        this.isEditing = this.hover = false;
      }
      this.isPosting = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500
      });
    },

    createMentionPoppers() {
      const mentionPopper = document.querySelector(`#mention-${this.comment.id}`);
      const showEvents = ['mouseenter', 'focus'];
      const hideEvents = ['mouseleave', 'blur'];

      for (const mention of this.comment.mentions) {
        const mentionLink = document.querySelector(`#mention-${this.comment.id}-${mention.user}-${mention.mentionPos}`);
        const popperInstance = createPopper(mentionLink, mentionPopper, {
          placement: 'top',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8]
              }
            }
          ]
        });

        const show = (mention, popperInstance) => {
          mentionPopper.setAttribute('data-show', '');
          const user = find(this.users, user => user.id === mention.user);
          const mentioned = `${user.first_name} ${user.last_name}`;
          this.mentionName = mentioned;
          this.avatarText = `${mentioned.split(' ')[0][0].toUpperCase()}${mentioned.split(' ')[1][0].toUpperCase()}`;
          popperInstance.update();
        };

        const hide = () => {
          mentionPopper.removeAttribute('data-show');
          this.mentionName = this.avatarText = null;
        };

        showEvents.forEach(event => {
          mentionLink.addEventListener(event, () => show(mention, popperInstance));
        });

        hideEvents.forEach(event => {
          mentionLink.addEventListener(event, hide);
        });
      }
    }
  },

  mounted() {
    this.createMentionPoppers();
  },

  updated() {
    if (!this.isEditing) {
      this.createMentionPoppers();
    }
  },

  computed: {
    ...mapGetters(['getLoggedInUser']),

    commentText() {
      if (!this.comment.mentions) return this.comment.text;

      var commentText = this.comment.text;
      for (const mention of this.comment.mentions) {
        const user = find(this.users, user => user.id === mention.user);
        if (user) {
          const fullName = `${user.first_name} ${user.last_name}`;
          const username = `<a href="#" id="mention-${this.comment.id}-${user.id}-${mention.mentionPos}" aria-describedby="tooltip">@${fullName}</a>`;
          commentText = commentText.replace('@{}', username);
        }
      }
      return commentText;
    },

    popUpOptions() {
      return {
        collection: [
          {
            trigger: '@',
            containerClass: 'tribute-container dropdown-menu',
            itemClass: 'dropdown-item svg-icon',
            selectClass: 'active-custom',
            values: this.users
              .filter(user => user.id !== this.getLoggedInUser.id)
              .map(user => ({
                key: getUsername(user),
                value: `${user.first_name} ${user.last_name}`
              })),
            selectTemplate: item => {
              return item ? `@${item.original.key}` : '';
            },
            menuItemTemplate(item) {
              const listView = `
                <div class="d-flex align-items-center">
                  <div class="avatar-wrapper d-flex justify-content-center align-items-center">
                    <span class="avatar">${item.original.value
                      .split(' ')[0][0]
                      .toUpperCase()}${item.original.value.split(' ')[1][0].toUpperCase()}</span>
                  </div>
                  <div class="ml-3">
                    ${item.original.value}
                  </div>
                </div>
              `;
              return listView;
            }
          }
        ],
        noMatchTemplate: () => {
          return `<li>${this.$t('discussionMsgs.noMentionMatch')}</li>`;
        }
      };
    }
  }
};
</script>
